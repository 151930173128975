import React from 'react';
import useShallowEqual from 'libraries/hooks/useShallowEqual';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const CasinoPlatform = ({ t }) => {
  const { isSupportWebp } = useShallowEqual((state) => ({
    isSupportWebp: state.isSupportWebp,
  }));
  const pngFile = isSupportWebp ? 'webp' : 'png';
  const navigate = useNavigate();
  const desktopNavigationHandle = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const demoRenderer = () => {
    var demoList = [];
    for (var i = 1; i < 5; i++) {
      demoList.push(
        <div key={`casino-demo${i}-item`} className='demo-item'>
          <div className='main-content'>
            <h4>{t(`casino.demo${i}.title`)}</h4>
            <p>{t(`casino.demo${i}.desc`)}</p>
          </div>
        </div>
      );
    }
    return demoList;
  };
  return (
    <div className='page-casino'>
      <div className='k-btn-toggle-group'>
        <div className='k-btn-toggle-wrap'>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/SportsPlatform')}>{t('nav.sports.platform')}</div>
          <div className='k-btn-toggle active'>{t('nav.casino.platform')}</div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/LightningPlatform')}>{t('nav.lightning.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/GlobalPlatform')}>{t('nav.global.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/InsightForge')}>{t('nav.InsightForge')}</div>
        </div>
      </div>
      <section className='casino-top'>
        <div className='main-wrap'>
        <div className='small-text-tag'>{t('casino.top.tag')}</div>
          <h1><strong>{t('casino.top.title2')}</strong>{t('casino.top.title1')}</h1>
          </div>
          <div className='kv-cp' >
            <img src={`/img/cp-kv.webp`} alt="lp" loading='lazy' />
          </div>
          <div className='main-wrap'>
          <div className='button-group'>
            <button className='k-btn btn-l btn-primary' onClick={() => window.open('https://www.er.casino')}>{t('casino.top.button')}</button>
          </div>
        </div>
      </section>
      {/* <section className='casino-top'>
        <div className='main-wrap'>
          <div className='small-text-tag'>{t('casino.top.tag')}</div>
          <h1><strong>{t('casino.top.title2')}</strong>{t('casino.top.title1')}</h1>
          <p>{t('casino.top.desc')}</p>
          <p className='desc-mobile'>{t('casino.top.desc.mobile')}</p>
          <div className='button-group'>
            <button className='k-btn btn-l btn-primary' onClick={() => window.open('https://www.er.casino')}>{t('casino.top.button')}</button>
          </div>
        </div>
        <div className='kv-left'><img src={`/img/key-visual1.${pngFile}`} alt="key-visual1" loading='lazy' /></div>
        <div className='kv-right'><img src={`/img/key-visual2.${pngFile}`} alt="key-visual2" loading='lazy' /></div>
        <div className='kv-right-mobile'><img src={`/img/key-visual-m.${pngFile}`} alt="key-mobile" loading='lazy' /></div>
      </section> */}
      <section className='casino-demo'>
        <h2 className='home-demo-title mobile-show'>{t('casino.demo.title')}</h2>
        <p className='home-demo-desc mobile-show'>{t('casino.demo.desc')}</p>
        <div className='main-wrap'>
          <div className='left'>
            <div className='mobile-frame'>
                <img src={`/img/13pro-frame.${pngFile}`} alt='mobile-frame' loading='lazy' />
                <div className='video-bg'>
                  <video playsInline autoPlay muted loop>
                    <source src='/img/casino_demo_video.mp4' type='video/mp4' />
                  </video>
                </div>
              </div>
          </div>
          <div className='right'>
            <h2 className='home-demo-title'>{t('casino.demo.title')}</h2>
            <p className='home-demo-desc'>{t('casino.demo.desc')}</p>
            <div className='home-demo-list'>{demoRenderer()}</div>
          </div>
        </div>
      </section>
      <section className='casino-intro'>
        <div className='left'>
          <h2 className='home-intro-title'>{t('casino.intro.title')}</h2>
          <p className='home-intro-desc'>{t('casino.intro.desc')}</p>
          <img src={`/img/casino-element.${pngFile}`} alt="design-element" loading='lazy' />
        </div>
        <div className='right'>
          <div className='casino-bg'>
            <img src={`/img/casino-intro-bg.${pngFile}`} alt="intro-visual" loading='lazy' />
          </div>
        </div>
      </section>
      <section className='casino-temp'>
        <div className='main-wrap'>
          <h2 className='casino-temp-title'>{t('casino.temp.title')}</h2>
          <p className='casino-temp-desc'>{t('casino.temp.desc')}</p>
          <div className='template-list'>
            <div><img src={`/img/er-demo1.${pngFile}`} alt='temp-white' loading='lazy' /></div>
            <div><img src={`/img/er-demo2.${pngFile}`} alt='temp-red' loading='lazy' /></div>
            <div><img src={`/img/er-demo3.${pngFile}`} alt='temp-green' loading='lazy' /></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(CasinoPlatform);
