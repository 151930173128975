import React from "react";

const Footer = () => {
  return(
  <footer className="k-footer">
    <div className="main-wrap">
        <div className="copyright">© K36. All rights reserved.</div>
    </div>
  </footer>
  )
};

export default Footer;
