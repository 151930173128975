import templateConfig from 'libraries/templateConfig';

let prefetchTag;
const append = (name, isWebp) => {
  prefetchTag = document.createElement('link');
  prefetchTag.rel = 'prefetch';
  prefetchTag.as = 'image';
  prefetchTag.href = `/img/temp/${name}.${isWebp ? 'webp' : 'jpg'}`;
  document.head.appendChild(prefetchTag);
};

export const prefetchImg = (isWebp) => {
  const { allEcTemp, allH5Temp, allGameTemp } = templateConfig;
  // mobile
  for (let i = 0; i < allH5Temp.length; i++) {
    if (allH5Temp[i].prefetch) {
      append(allH5Temp[i].name, isWebp);
    }
  }
  // desktop
  for (let i = 0; i < allEcTemp.length; i++) {
    if (allEcTemp[i].prefetch) {
      append(allEcTemp[i].name, isWebp);
    }
  }
  // gameLobby
  Object.keys(allGameTemp).forEach(key => {
    for(let i = 0 ; i < allGameTemp[key].length; i++){
      if(allGameTemp[key][i].prefetch){
        append(allGameTemp[key][i].name, isWebp);
      }
    }
  })
  
};
