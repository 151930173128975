import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { k36Langs } from 'libraries/enums';

const getResources = () =>{
  k36Langs.forEach((lang) => {
    import(`./../locales/${lang}.js`)
      .then((res) => {
        i18n.addResourceBundle(lang, 'translation', res.default);
      });
  });
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    },
    lng: window.localStorage.getItem('locale') || navigator.language || k36Langs[0],
    // 預設語言: 上次選擇語系>瀏覽器語系>預設語系(英文)
    fallbackLng: k36Langs[0], // 如果當前切換的語言沒有對應的翻譯則使用這個語言
    resources: getResources(),
    interpolation: {
      escapeValue: false
    }
  });
