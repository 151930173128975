import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { k36Langs } from 'libraries/enums';
const initialState = {
  currentLocal: window.localStorage.getItem('locale')?.replace(/^"|"$/g, '') || k36Langs[0],
  templateWindow: false,
  templateNavigation: 'mobile',
  config: {
    wlName: '',
    wlCode: '',
    selectedEcTemp: null,
    selectedH5Temp: null,
    selectedGameLobby: {
      Live: 'live1',
      Egame: 'egame1',
      Sports: 'sports1',
      Mpg: 'fishing1',
      Animal: 'animal1',
      Lottery: 'lottery1',
      Esports: 'esports1',
      Chess: 'chess1',
    },
    selectedEcLang: [],
    defaultEcLang: null,
    selectedCmsLang: [],
    defaultCmsLang: 'en-US',
    defaultCurrency: null,
    defaultTelCountry: null,
    isSupportWebp: false,
    imgFormat: 'jpg',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_TEMPLATE_WINDOW':
      return { ...state, templateWindow: !state.templateWindow };
    case 'UPDATE_TEMPLATE_NAVIGATION':
      return {
        ...state,
        templateNavigation: action.payload,
      };
    case 'UPDATE_TEMPLATE_WINDOW':
      return {
        ...state,
        templateWindow: action.payload,
      };
    case 'UPDATE_CURRENT_LOCAL':
      return {
        ...state,
        currentLocal: action.payload,
      };
    case 'UPDATE_CONFIG':
      return {
        ...state,
        config: { ...state.config, ...action.payload },
      };
    case 'UPDATE_IMAGE_FORMAT_SUPPORT':
      return {
        ...state,
        isSupportWebp: action.payload,
        imgFormat: action.payload ? 'webp' : 'jpg'
      };
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
