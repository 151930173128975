import React from 'react';
import useShallowEqual from 'libraries/hooks/useShallowEqual';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const SportsPlatform = ({ t }) => {
  const { isSupportWebp } = useShallowEqual((state) => ({
    isSupportWebp: state.isSupportWebp,
  }));
  const pngFile = isSupportWebp ? 'webp' : 'png';
  const navigate = useNavigate();
  const desktopNavigationHandle = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const featureRenderer = () => {
    var demoList = [];
    for (var i = 1; i < 5; i++) {
      demoList.push(
        <div key={`sports-feature${i}-item`} className='feature-item'>
          <div className='feature-img'>
            <img src={`/img/sports-feature${i}.${pngFile}`} alt={`sports-feature${i}`} loading='lazy' />
          </div>
          <div className='feature-content'>
            <h4>{t(`sports.feature${i}.title`)}</h4>
            <p>{t(`sports.feature${i}.desc`)}</p>
          </div>
        </div>
      );
    }
    return demoList;
  };
  return (
    <div className='page-sports'>
      <div className='k-btn-toggle-group'>
        <div className='k-btn-toggle-wrap'>
          <div className='k-btn-toggle active'>{t('nav.sports.platform')}</div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/CasinoPlatform')}>{t('nav.casino.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/LightningPlatform')}>{t('nav.lightning.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/GlobalPlatform')}>{t('nav.global.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/InsightForge')}>{t('nav.InsightForge')}</div>
        </div>
      </div>
      <section className='sports-top'>
        <div className='main-wrap'>
        <div className='small-text-tag'>{t('sports.top.tag')}</div>
          <h1><strong>{t('sports.top.title1')}</strong>{t('sports.top.title2')}</h1>
          </div>
          <div className='kv-sp' >
            <img src={`/img/sp-kv.webp`} alt="lp" loading='lazy' />
          </div>
          <div className='main-wrap'>
          <div className='button-group'>
            <button className='k-btn btn-l btn-primary' onClick={() => window.open('https://www.luckykismat.com/gamelobby/sports/sport-page')}>{t('sports.top.button')}</button>
          </div>
        </div>
      </section>
      {/* <section className='sports-top'>
        <div className='main-wrap'>
          <div className='small-text-tag'>{t('sports.top.tag')}</div>
          <h1><strong>{t('sports.top.title1')}</strong>{t('sports.top.title2')}</h1>
          <p>{t('sports.top.desc')}</p>
          <p className='desc-mobile'>{t('sports.top.desc.mobile')}</p>
          <div className='button-group'>
            <button className='k-btn btn-l btn-primary' onClick={() => window.open('https://www.luckykismat.com/gamelobby/sports/sport-page')}>{t('sports.top.button')}</button>
          </div>
        </div>
        <div className='kv-left'><img src={`/img/key-sports-visual1.${pngFile}`} alt="key-visual1" loading='lazy' /></div>
        <div className='kv-right'><img src={`/img/key-sports-visual2.${pngFile}`} alt="key-visual2" loading='lazy' /></div>
        <div className='kv-right-mobile'><img src={`/img/key-sports-visual2.${pngFile}`} alt="key-mobile" loading='lazy' /></div>
      </section> */}
      <section className='sports-feature'>
        <div className='main-wrap'>
          <h2 className='sports-feature-title'>{t('sports.feature.title')}</h2>
          <div className='sports-feature-list'>{featureRenderer()}</div>
        </div>
      </section>
      <section className='sports-temp'>
        <div className='main-wrap'>
          <h2 className='sports-temp-title'>{t('sports.temp.title')}</h2>
          <p className='sports-temp-desc'>{t('sports.temp.desc')}</p>
          <div className='template-list'>
            <div><img src={`/img/sports-demo1.${pngFile}`} alt='temp-white' loading='lazy' /></div>
            <div><img src={`/img/sports-demo2.${pngFile}`} alt='temp-red' loading='lazy' /></div>
            <div><img src={`/img/sports-demo3.${pngFile}`} alt='temp-green' loading='lazy' /></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(SportsPlatform);
