export const UpdateConfig = (payload) => {
  return {
    type: "UPDATE_CONFIG",
    payload,
  };
};
export const UpdateTemplateWindow = (payload) => {
  return {
    type: "UPDATE_TEMPLATE_WINDOW",
    payload,
  };
};
export const UpdateTemplateNavigation = (payload) => dispatch => {
  dispatch({
    type: 'UPDATE_TEMPLATE_NAVIGATION',
    payload,
  });
  return Promise.resolve();
};
export const UpdateImgFormatSupport = (payload) => ({
  type: 'UPDATE_IMAGE_FORMAT_SUPPORT',
  payload,
})
